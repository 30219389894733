<template>
  <div class="app-share">
    <div class="p-5 p-mobile">
      <div class="row">
        <div class="col-md-3 text-right user-share-image">
          <b-img
            class="w-mobile"
            :src="require('@/assets/images/app-icons/qr_images/casual-life-3d-young-woman-standing-and-pointing-at-her-phone.png')"
          />
        </div>
        <div class="offset-md-3 col-md-6">
          <h2 class="font-weight-bolder">
            {{ $t('Powered by') }}
          </h2>
          <div class="w-100 share-logo text-center">
            <b-img :src="require('@/assets/images/app-icons/qr_images/vector-file.svg')" />
          </div>
          <div class="wrap-qr-code-share">
            <b-img
              :src="$helpers.imageHelper(shareInfo.qrcode_url)"
              @error="$helpers.imageError"
            />
            <p class="mt-1 font-18">
              {{ $t('Scan the QR Code') }}
            </p>
            <h1
              v-if="shareInfo.store_name"
              class="font-weight-bolder share-company-name"
            >
              {{ shareInfo.store_name }}
            </h1>
            <h1
              v-else
              class="font-weight-bolder share-company-name"
            >
              {{ shareInfo.company_name }}
            </h1>
          </div>
        </div>
        <div class="col-md-3 text-left user-share-image user-share-image-start">
          <b-img
            class="w-mobile"
            :src="require('@/assets/images/app-icons/qr_images/casual-life-3d-young-man-holding-blue-smartphone.png')"
          />
        </div>
      </div>
      <div class="no-print col-md-12 text-center mt-5">
        <b-img
          :src="require('@/assets/images/app-icons/print.png')"
          style="width: 40px"
          class="cursor-pointer"
          @click="openPrint"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { useUserUi } from '../users/useUser'

export default {
  components: {
    BImg,
  },
  data() {
    return {
      shareInfo: {},
    }
  },
  mounted() {
    this.commonShareInfo()
  },
  methods: {
    openPrint() {
      window.print()
    },
    commonShareInfo() {
      const { commonShareInfo } = useUserUi()
      showLoader()
      commonShareInfo({
        id: this.$route.params.shopId ? this.$route.params.shopId : this.$route.params.providerId,
        type: this.$route.params.shopId ? 'SHOP' : 'PROVIDER',
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.shareInfo = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
[dir] .horizontal-layout.navbar-floating:not(.blank-page) .app-content.padding-top-share {
  padding-top: 65px !important;
  background-color: #ebf4f6;
}

.app-share {
  position: relative;
  .user-share-image {
    display: grid;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 20%;
    left: 22%;

    &.user-share-image-start {
      justify-content: flex-start;
      left: unset;
      right: 13%;
    }

    img {
      width: 80%;
    }
  }

  .share-logo {
    img {
      width: 50%;
    }
  }

  .share-company-name {
    font-size: 3.5rem;
  }
}

@media print {
  .no-print {
    display: none;
  }
  .app-share {
    .user-share-image {
      img {
        width: 100%;
      }
    }

    .user-share-image {
      left: 15%;

      &.user-share-image-start {
        right: 4%;
      }

      img {
        width: 80%;
      }
    }

    .share-logo {
      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  body .wrap-qr-code-share img {
    width: 200px !important;
  }

  .app-share {
    .share-logo {
      img {
        width: 80%;
      }
    }

    .user-share-image {
      position: unset;
      transform: unset;
      left: unset;
      right: unset;
    }

    .share-company-name {
      font-size: 2.5rem;
    }
  }

  body .app-share .w-mobile {
    width: 75% !important;
  }

  [dir] body .p-mobile {
    padding: 1rem !important;
  }
}
</style>

<style scoped>
.wrap-qr-code-share {
  margin-top: 25px;
  text-align: center;
}

.wrap-qr-code-share img {
  width: 300px;
  margin: 0 auto;
}
</style>
